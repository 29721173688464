import { DateSchema, ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import { StripeProducts, TotalUsages } from '@racemap/sdk/schema/billing';
import { BillableItemTypes, User as ZodUser, UserDocumentSchema } from '@racemap/sdk/schema/user';
import { Document, Types } from 'mongoose';
import Stripe from 'stripe';
import { z } from 'zod';
import { PRICING_VERSIONS } from '../consts/billing';
import { Brand } from './brand';
import { EventPageViewTypes } from './events';
export {
  UserDocumentSchema,
  type UserDocument,
  type Admin,
  type UserDocumentWithStripeCustomerInfos,
} from '@racemap/sdk/schema/user';

export enum TOSVersions {
  NONE = 'NONE',
  '2019-10' = '2019-10',
}

export const CURRENT_TOS_VERSION = TOSVersions['2019-10'];

export type TaxId = {
  value: string;
};

export type Integrations = {
  stripeId: string;
  mailchimpSubscribed: boolean;
  raceResultApiKey: string | null;
  raceResultCustomerId: string | null;
};

export type UserStatistics = {
  numberCreatedEvents: number;
  numberPaidEvents: number;
};

export type UserCredentials = {
  email: string;
  password: string;
};

export interface UserSubscriptions {
  [BillableItemTypes.APP]: number;
}

export type User_Legacy = {
  id: string;
  admin?: boolean;
  isBetaUser?: boolean;
  receivedAt?: number;
  updatedAt?: string;
  createdAt?: string;
  isConfirmed?: boolean;
  payments?: {
    lastUsageSync: string;
  };
  name?: string;
  company?: string;
  email?: string;
  phoneNumber?: string;
  country?: string;
  useTimingAPI?: boolean;
  isReseller?: boolean;
  password?: string;
  fakeUser?: boolean;
  acceptedTOSVersion?: TOSVersions;
  apiToken?: string;
  stats?: UserStatistics;
  parentId: string | ObjectId | null;
  integrations?: Integrations;
  subscriptions: UserSubscriptions;
  skipNotifications?: boolean;
  checkout?: {
    address: Stripe.Address;
    taxIds: Array<Stripe.TaxId>;
    shipping: Stripe.Customer.Shipping | null;
    defaultCard: Stripe.Source.Card | null;
  };
};
export type ResellerLegacy = User_Legacy & {
  isReseller: true;
};
export type BetaUserLegacy = User_Legacy & {
  isBetaUser: true;
};
export type ChildUserLegacy = User_Legacy & {
  parentId: string;
};
export type RegularUserLegacy = User_Legacy & {
  admin: false;
  parentId: null;
  isReseller: false;
};
export type AdminLegacy = User_Legacy & {
  admin: true;
};

export type User = ZodUser;
export type Users = Array<User_Legacy>;

export const UserSnapshotDocumentSchema = z.object({
  _id: ObjectIdSchema,
  userId: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  user: UserDocumentSchema,
});

export type UserSnapshotDocument = z.infer<typeof UserSnapshotDocumentSchema> &
  Document<Types.ObjectId>;

export interface UserWithBrands extends User_Legacy {
  brands: Array<Brand>;
}

export interface BorrowerDocument extends Document {
  id: ObjectId;
  startTime: Date;
  endTime: Date;
}

// editor user added from backend to events
export type Editor = User_Legacy & {
  id: string | ObjectId;
  name: string;
  isReseller: boolean;
};

export type Editors = Array<Editor>;

// user response from search user input
export type SearchedUser = Editor & {
  email: string;
};

export type SearchedUsers = Array<SearchedUser>;

export type DailyPageview = { time: string; count: number };

export type BillingInfoWithVAT = BillingInfo & { VAT: number };

export type BillableNowItemTypes = Exclude<
  BillableItemTypes,
  | BillableItemTypes.SIM_CARD
  | BillableItemTypes.TRACKER_MANAGEMENT
  | BillableItemTypes.APP
  | BillableItemTypes.SMS
  | BillableItemTypes.ACTIVATE_EVENT
  | BillableItemTypes.EVENT_DAY
  | BillableItemTypes.DATA_USAGE_ZONE_1
  | BillableItemTypes.DATA_USAGE_ZONE_2
  | BillableItemTypes.DATA_USAGE_ZONE_3
  | BillableItemTypes.EXTEND_EVENT
>;
export type FreeBillableItemTypes =
  | BillableItemTypes.FREE_EVENT_CYCLE
  | BillableItemTypes.FREE_TIMING
  | BillableItemTypes.FREE_MONITOR
  | BillableItemTypes.FREE_SPONSOR
  | BillableItemTypes.FREE_DATA_FEED;
export type BillableNowCountList = Partial<Record<StripeProducts, number>>;
export type FreeItemCounts = Record<FreeBillableItemTypes, number>;

export type EventStatistic = {
  eventId: string;
  eventName: string;
  billableNow: BillableNowCountList;
  totalCounts: BillableNowCountList;
  pricingVersion: PRICING_VERSIONS;
  loadCounts: Record<EventPageViewTypes, number>;
  freeItemCounts: FreeItemCounts;
};

export type EventStatistics = Array<EventStatistic>;

export type UsageGroupedByTimeKeyAndZone = {
  [timeKey: string]: {
    [BillableItemTypes.DATA_USAGE_ZONE_1]?: number;
    [BillableItemTypes.DATA_USAGE_ZONE_2]?: number;
    [BillableItemTypes.DATA_USAGE_ZONE_3]?: number;
  };
};

export type BillingInfo = {
  dailyPageviews: Array<DailyPageview>;
  eventStatistics: EventStatistics;
  totals: TotalUsages;
  totalLoadCounts: Record<EventPageViewTypes, number>;
  startTime: string;
  endTime: string;
  dataUsages: UsageGroupedByTimeKeyAndZone;
};

export type BillingInvoice = {
  id: string;
  invoicePdf: string;
  invoiceWeb: string;
  createdAt: string;
  paid: boolean;
  number: string;
  amount: number;
};
