import {
  AllPingsQuery,
  DBSortOrder,
  DBTimestampType,
  HTTPFetchError,
  RacemapAPIClient,
} from '@racemap/utilities/api-client';
import { OneDayInMillis } from '@racemap/utilities/consts/time';
import { isDefined, isNotNull } from '@racemap/utilities/functions/validation';
import { calculateDistanceInMeter } from '@racemap/utilities/point_utils';
import { Shadowtrack } from '@racemap/utilities/types/events';
import { EventTrackObject } from '@racemap/utilities/types/geos';
import {
  PlacingToShadowtrack,
  PreparedRead,
  PreparedReader,
  PreparedTransponder,
  PreparedTransponderRead,
  ReaderType,
  Readers,
  Reads,
  Transponders,
} from '@racemap/utilities/types/prediction';
import { Probe } from '@racemap/utilities/types/tpom-probe';
import { BoxInfo, JSONTimePing, TransponderInfo } from '@racemap/utilities/types/trackping';
import { Ping, PingType } from '@racemap/utilities/types/types';
import { Immutable, produce } from 'immer';
import moment from 'moment';
import { StoreApi } from 'zustand';
import { getBounds, isLocationInBounds } from '../lib/map-helpers';
import { DraftState, State } from './reducers';

const apiClient = RacemapAPIClient.fromWindowLocation();
// deprecated: const apiClient = new RacemapAPIClient('https://racemap.com');
// if you want to use racemap.com, set the PROXY_API_HOST_OVERWRITE environment variable to 'https://racemap.com'
// and restart proxy service
const trackpingAPIClient = apiClient.trackping();

export type ReadsFilter = Omit<AllPingsQuery, 'startTime' | 'endTime'> & {
  startTime: Date;
  endTime: Date;
};

export interface PredictionState {
  prediction: {
    probes: {
      items: Map<string, Array<Probe>>;
    };
    readers: {
      items: Readers;
    };
    transponders: {
      items: Transponders;
    };
    reads: {
      items: Reads;
    };
    readsFilter: ReadsFilter;
    isLoading: boolean;
    chunkedShadowTrack: EventTrackObject | null;
    // maybe load the simple shadowtrack to make the rendering in the map simpler
    actions: {
      loadReadersAndTransponders: (eventId: string) => Promise<void>;
      loadProbes: (eventId: string) => Promise<void>;
      loadLastProbes: (eventId: string) => Promise<void>;
      loadShadowtrack: (eventId: string) => Promise<void>;
      setReadsFilter: (filter: Partial<ReadsFilter>) => void;
    };
    getter: {
      lastProbe: (eventId: string) => Probe | null;
    };
  };
}

export const createPredictionStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): PredictionState => ({
  prediction: {
    probes: {
      items: new Map(),
    },
    transponders: {
      items: new Map(),
    },
    readers: {
      items: new Map(),
    },
    reads: {
      items: new Map(),
    },
    chunkedShadowTrack: null,
    isLoading: false,
    filter: {},
    readsFilter: {
      eventId: '',
      startTime: new Date(new Date().valueOf() - 5 * OneDayInMillis),
      endTime: new Date(),
      timestampType: DBTimestampType.ReceivedAt,
      order: DBSortOrder.Descending,
      limit: 100,
      offset: 0,
      dummy: false,
    },
    actions: {
      loadProbes: async (eventId) => {
        set(
          produce((s: DraftState) => {
            s.prediction.isLoading = true;
          }),
        );
        const probes = await apiClient.getTPOMProbes({
          eventId: eventId,
          limit: 20,
        });

        set(
          produce((s: DraftState) => {
            s.prediction.probes.items.set(eventId, probes);
            s.prediction.isLoading = false;
          }),
        );
      },
      loadLastProbes: async (eventId) => {
        set(
          produce((s: DraftState) => {
            s.prediction.isLoading = true;
          }),
        );
        const probes = await apiClient.getTPOMProbes({
          eventId: eventId,
          since: moment().subtract(5, 'minutes').toDate(),
        });

        set(
          produce((s: DraftState) => {
            s.prediction.probes.items.set(eventId, probes);
          }),
        );
      },
      loadReadersAndTransponders: async (eventId) => {
        try {
          set(
            produce((s: DraftState) => {
              s.prediction.isLoading = true;
            }),
          );
          const { transponders: rawTransponders, boxes: rawReaders } =
            await trackpingAPIClient.getEventTranspondersAndReaders(eventId);

          const newTransponders = rawTransponders.map(prepareTransponder);
          const readersFromTransponders: Array<BoxInfo> = newTransponders
            .map((t) => t.rawLatestRead)
            .filter(isDefined)
            .filter((r) => r.type === 'accepted')
            .map((r) => ({
              boxId: r.ping.boxId,
              customerId: r.ping.customerId,
              latestPing: r,
              offsets: r.type === 'accepted' ? [r.offset] : [],
            }));

          const newReaders = new Map(
            [...readersFromTransponders, ...rawReaders]
              .map((reader) => prepareReader(reader))
              .map((r) => [r.id, r]),
          );

          const readersWithMapping = mapReaders(
            Array.from(newReaders.values()),
            get().prediction.chunkedShadowTrack,
            !!get().users.getter.currentUser()?.admin,
          );

          set(
            produce((s: DraftState) => {
              for (const reader of readersWithMapping) {
                updateReaderEntry(s, reader);
              }
              for (const transponder of newTransponders) {
                s.prediction.transponders.items.set(transponder.id, transponder);
              }
              s.prediction.isLoading = false;
            }),
          );
        } catch (err) {
          if (err instanceof HTTPFetchError) return;
          console.error(err);
          // throw err
        }
      },
      loadShadowtrack: async (eventId) => {
        try {
          // TODO: load here later directly the splits!
          // const event = get().events.getter.currentEvent();
          // const simpleShadowtrack = get().events.getter.shadowtrack();
          // const splits = simpleShadowtrack?.properties.splits || [];
          const shadowtrackSimple = (await apiClient.getEventShadowGeo(eventId)).features[0];
          const shadowtrackCoordinatesChunked = await apiClient
            .asAnonymous()
            .getEventChunkedShadowGeo(eventId);
          if (shadowtrackSimple == null || shadowtrackCoordinatesChunked == null) return;

          const shadowtrack: Shadowtrack = {
            type: 'Feature',
            id: shadowtrackSimple.id,
            geometry: {
              type: 'LineString',
              coordinates: shadowtrackCoordinatesChunked,
            },
            properties: {
              ...shadowtrackSimple.properties,
              name: 'Chunked Shadowtrack',
            },
          };

          set(
            produce((s: DraftState) => {
              s.prediction.chunkedShadowTrack = shadowtrack;
            }),
          );
        } catch (err) {
          if (err instanceof HTTPFetchError) return;
          console.error(err);
          // throw err
        }
      },
      setReadsFilter: (filterPatch: Partial<ReadsFilter>) => {
        set(
          produce((s: DraftState) => {
            s.prediction.readsFilter = { ...s.prediction.readsFilter, ...filterPatch };
          }),
        );
      },
    },
    getter: {
      lastProbe: (eventId) => {
        const probes = get().prediction.probes.items.get(eventId);
        if (probes == null || probes.length === 0) return null;

        const sortedProbes = [...probes].sort(
          (p1, p2) => new Date(p1.createdAt).getTime() - new Date(p2.createdAt).getTime(),
        );

        return sortedProbes[0];
      },
    },
  },
});

export const prepareReader = (reader: BoxInfo, name?: string): PreparedReader => {
  const latestRead = reader.latestPing.ping;

  return {
    id: reader.boxId,
    name,
    readerType: reader.boxId.startsWith('D-') ? ReaderType.RRDecoder : ReaderType.RRTrackbox,
    customerId: reader.customerId,
    latestRead: {
      accepted: reader.latestPing.type === 'accepted',
      timestamp: new Date(latestRead.timestamp),
      pingType: latestRead.pingType,
      transponderId: latestRead.transponderId,
      rssi: latestRead.rssi,
      receivedAt: new Date(latestRead.receivedAt),
    },
    location:
      latestRead.lat != null && latestRead.lng != null
        ? {
            lat: latestRead.lat,
            lng: latestRead.lng,
            elv: latestRead.elv,
          }
        : undefined,
    readCount: latestRead.hits,
    updatedAt: new Date(latestRead.receivedAt),
    placing: PlacingToShadowtrack.FarAway,
    mappings: reader.offsets.map((o) => ({
      pointIndex: o / 10,
    })),
  };
};

export const prepareRead = (read: Ping): PreparedRead => {
  return {
    readerId: read.boxId,
    userId: read.userId,
    customerId: read.customerId,
    pingType: read.pingType,
    count: read.hits,
    dropped: [],
    rssi: read.rssi || -300,
    transponderId: read.transponderId || '',
    receivedAt: new Date(read.receivedAt),
    timestamp: new Date(read.timestamp),
    wasUserDefined: read.wasUserDefined,
    timingName: read.timingName,
    location:
      read.lat != null && read.lng != null
        ? {
            state: read.pos_state,
            lat: read.lat,
            lng: read.lng,
            elv: read.elv,
          }
        : undefined,
  };
};

const prepareTimePing = (
  timePing: JSONTimePing,
  readers: Immutable<Array<PreparedReader>>,
): PreparedRead => {
  const belongingReader = readers.find((r) => r.subReaderIds?.includes(timePing.timingId));
  const location =
    timePing.lat != null && timePing.lng != null
      ? {
          lat: timePing.lat,
          lng: timePing.lng,
          elv: timePing.alt || undefined,
        }
      : belongingReader?.location != null
      ? belongingReader.location
      : undefined;

  return {
    readerId: belongingReader?.id || timePing.timingName || 'UNKNOWN',
    userId: timePing.userId,
    customerId: undefined,
    pingType: PingType.TimingPing,
    dropped: [],
    rssi: 0,
    transponderId: timePing.chipId,
    receivedAt: new Date(timePing.receivedAt),
    timestamp: new Date(timePing.timestamp),
    location,
  };
};

const prepareTransponder = (transponder: TransponderInfo): PreparedTransponder => {
  const latestPing = transponder.latestPing?.ping;
  const latestRead: PreparedTransponderRead | null =
    latestPing != null
      ? {
          pingType: latestPing.pingType,
          readerId: latestPing.boxId,
          timestamp: new Date(latestPing.timestamp),
          rssi: latestPing.rssi,
          receivedAt: new Date(latestPing.receivedAt),
          dropped:
            transponder.latestPing?.type === 'rejected' ? transponder.latestPing.reasons : [],
          location: {
            lat: latestPing.lat,
            lng: latestPing.lng,
            elv: latestPing.elv,
          },
          offset:
            transponder.latestPing?.type === 'accepted' ? transponder.latestPing.offset : null,
        }
      : null;

  return {
    id: transponder.transponderId,
    userId: latestPing?.userId || '',
    customerId: latestPing?.customerId || '',
    latestRead,
    rawLatestRead: transponder.latestPing,
    minTimestamp: latestPing?.minTimestamp != null ? new Date(latestPing.minTimestamp) : null,
    updatedAt: latestPing?.receivedAt != null ? new Date(latestPing?.receivedAt) : null,
    count: latestPing?.hits || -1,
  };
};

const prepareBoxPing = (ping: Ping): PreparedReader => {
  return {
    id: ping.boxId,
    readerType: ping.boxId.startsWith('D-') ? ReaderType.RRDecoder : ReaderType.RRTrackbox,
    customerId: ping.customerId,
    updatedAt: new Date(ping.receivedAt),
    location: {
      lat: ping.lat,
      lng: ping.lng,
    },
    placing: PlacingToShadowtrack.FarAway,
    mappings: [],
  };
};

const getCounts = (reads: Immutable<Array<PreparedRead>>): Map<string, number> => {
  const counts = new Map();

  for (const read of reads) {
    const currentValue = counts.get(read.readerId);
    if (currentValue == null) {
      counts.set(read.readerId, 1);
    } else {
      counts.set(read.readerId, currentValue + 1);
    }
  }

  return counts;
};

const updateReaderEntry = (state: DraftState, reader: PreparedReader): void => {
  const existingReader = state.prediction.readers.items.get(reader.id);
  if (existingReader == null) {
    state.prediction.readers.items.set(reader.id, reader);
    return;
  }

  state.prediction.readers.items.set(reader.id, {
    ...existingReader,
    ...reader,
    mappings: existingReader.mappings.concat(reader.mappings),
  });
};

export const mapReaders = (
  readers: Array<PreparedReader>,
  shadowtrack: Immutable<Shadowtrack> | null,
  isAdmin: boolean,
): Array<PreparedReader> => {
  const bounds = shadowtrack ? getBounds([shadowtrack]) : null;

  const mappedReaders = readers.map((reader) => {
    if (reader == null) return null;
    if (reader.id === 'T-19998' && !isAdmin) return null;

    const preparedReader = produce(reader, (r) => {
      // update attributes
      if (reader.location != null) {
        if (bounds == null) {
          r.placing = PlacingToShadowtrack.NoShadowTrack;
        } else if (r.mappings.length > 0) {
          r.placing = PlacingToShadowtrack.Mapped;
          r.mappings = r.mappings.map((m) => {
            const mappingLocation = shadowtrack?.geometry.coordinates[m.pointIndex];
            const distance =
              reader.location != null && mappingLocation != null
                ? calculateDistanceInMeter(
                    reader.location.lat,
                    reader.location.lng,
                    mappingLocation[1],
                    mappingLocation[0],
                  )
                : -1;
            return {
              pointIndex: m.pointIndex,
              distance,
            };
          });
        } else if (r.location != null && isLocationInBounds(r.location, bounds, 0.2)) {
          r.placing = PlacingToShadowtrack.Near;
        } else {
          r.placing = PlacingToShadowtrack.FarAway;
        }
      }
    });

    return preparedReader;
  });

  return mappedReaders.filter(isNotNull);
};
