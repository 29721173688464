import styled from '@emotion/styled';
import { StripeProducts } from '@racemap/sdk/schema/billing';
import { BillableItemTypes } from '@racemap/sdk/schema/user';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { AddOnLabels } from '@racemap/utilities/consts/events';
import {
  getEventCyclesOfTimeRange,
  getPricingRulesOfEvent,
  getProductsOfBillingCycles,
  getRegisteredCountProducts,
  isBillableItem,
  isCountBillableItemType,
  isEventDayBasedBillableItem,
  isFreeBillableItem,
} from '@racemap/utilities/functions/billing';
import { userNeedsVAT } from '@racemap/utilities/functions/userNeedsVAT';
import { Card } from 'antd';
import humanizeDuration from 'humanize-duration';
import { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useCurrentUser, useGetProductPrice, useUser } from '../../../lib/customHooks';
import { formatPriceWithVAT, getLabelForBillingItemType } from '../../../misc/pricing';
import { CurrentEvent } from '../../../store/events/events_reducers';

interface Props {
  event: Immutable<CurrentEvent>;
  title?: string;
}

export const AlreadyPayedList: FC<Props> = ({ event, title }) => {
  const { user: creator } = useUser({ userId: event.creatorId });
  const getPrice = useGetProductPrice({ isReseller: creator?.isReseller });

  if (creator == null) return null;

  const needsVAT = userNeedsVAT(creator.checkout?.address, creator.checkout?.taxIds);
  const { startTime, endTime } = event;
  const billableEvents = event.billableItems.filter(isEventDayBasedBillableItem);
  const basePriceEvent = event.billableItems.find((e) => e.type === BillableItemTypes.BASE_PRICE);
  const freeBillableItems = event.billableItems.filter(isFreeBillableItem);
  const billedItems = event.billableItems.filter(isBillableItem);
  const pricing = getPricingRulesOfEvent(event);

  if (startTime == null || endTime == null || creator == null) return null;

  const billingCycles = getEventCyclesOfTimeRange(billableEvents, pricing.numDaysEventCycle);
  const billingCycleProducts = getProductsOfBillingCycles(
    billingCycles,
    basePriceEvent != null,
    freeBillableItems,
  );
  const countProducts = getRegisteredCountProducts(billedItems);
  const productsPrepared = Object.entries({ ...billingCycleProducts, ...countProducts }).filter(
    ([, amount]) => amount > 0,
  ) as Array<[StripeProducts, number]>;

  const startTimeObj = billingCycles.eventCycles.reduce<null | DateTime>(
    (acc, cycle) =>
      DateTime.fromJSDate(cycle.startDate) < (acc || Infinity)
        ? DateTime.fromJSDate(cycle.startDate)
        : null,
    null,
  );
  const endTimeObj = billingCycles.eventCycles.reduce<null | DateTime>(
    (acc, cycle) =>
      DateTime.fromJSDate(cycle.endDate) > (acc || -Infinity)
        ? DateTime.fromJSDate(cycle.endDate)
        : null,
    null,
  );
  const duration =
    startTimeObj != null && endTimeObj != null ? endTimeObj.diff(startTimeObj) : null;

  if (basePriceEvent != null) {
    productsPrepared.unshift([StripeProducts.BASE_PRICE, 1]);
  }

  return (
    <Container>
      <h5>{title || 'Before extension'}:</h5>

      <table>
        <tbody>
          <tr>
            <td>
              <strong>Start:</strong> {startTimeObj?.toLocaleString(DateTime.DATETIME_FULL) || '--'}
            </td>
            <td>
              <strong>End:</strong> {endTimeObj?.toLocaleString(DateTime.DATETIME_FULL) || '--'}
            </td>
          </tr>
          <tr>
            <td>
              {duration != null
                ? humanizeDuration(duration.toMillis(), { largest: 2, language: 'en' })
                : '--'}{' '}
              duration
            </td>
            <td>{billingCycles.eventCycles.length}x Event Cycles</td>
          </tr>
        </tbody>
      </table>

      {productsPrepared.length > 0 && (
        <>
          <section>Already charged usage from the event start until now:</section>

          <table>
            <thead>
              <tr>
                <th className="labelColumn">Product</th>
                <th className="unitColumn">Units</th>
                <th>Price per Unit</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {productsPrepared.map(([productKey, amount]) => (
                <tr key={productKey}>
                  <td className="labelColumn">{getLabelForBillingItemType(productKey)}</td>
                  <td className="unitColumn">{amount}</td>
                  <td>
                    {!isCountBillableItemType(productKey) &&
                      formatPriceWithVAT(getPrice?.(productKey, 1) || 0, needsVAT)}
                  </td>
                  <td>
                    {!isCountBillableItemType(productKey) &&
                      formatPriceWithVAT(getPrice?.(productKey, amount) || 0, needsVAT)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Container>
  );
};

const Container = styled(Card)`
    width: 100%;
    border-color: ${RacemapColors.DarkGray};
    background-color: ${RacemapColors.LightLightGray};

    .ant-card-body {
        padding: 8px 15px;
    }

    h5 {
        font-size: 1.1rem;
    }

    section {
        margin-bottom: 0.6rem;
    }

    table {
        width: 100%;
        margin-bottom: 0.6rem;

        th {
            font-weight: 600;
            border-bottom: 1px solid ${RacemapColors.Gray};
            width: 25%;
        }

        td {
          width: 25%;
        }

        .labelColumn {
          width: 40%;
        }

        .unitColumn {
          width: 10%;
          text-align: center;
        }
        
    }
`;
